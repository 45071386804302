<template>
  <main>
    <section
      v-if="is_first_view"
      class="skincareItemCheck"
    >
      <div class="skincareItemCheck_container">
        <p>あなたの肌にあうコスメが見つかる</p>
        <h1>コンシェルジュに相談</h1>
        <div class="skincareItemCheck_explanation">
          <p>肌質診断の結果をもとに、スキンケア・メイクのどちらかを選択し、コンシェルジュの提案を受けることができます。</p>
          <p>
            あなたの現在の肌状態やお悩み、商品の価格帯などについての質問に回答していくことで、
            あなたの肌質に合った化粧品を見つけるお手伝いをいたします。
          </p>
        </div>
        <a
          disable
          :class="[hasSkintypeId? 'itemSuggestions_btn': 'itemSuggestions_btn_disabled']"
          @click="startFlow"
        >
          START
        </a>
      </div>
    </section>
    <section
      v-if="!hasSkintypeId"
      class="skintype_diag_notyet_container"
    >
      <div class="skintype_diag_notyet">
        <p>
          肌質診断の結果がまだありません。<br><br>
          お客さまの肌に合うスキンケア・コスメを探すためにはまず自分の肌を知ることが大切です。<br>
          まずは肌質診断をしてみましょう。
        </p>
        <div class="skintypeCheck_btn">
          <router-link
            to="/flow/skin-type-diag"
          >
            肌質診断を受ける
          </router-link>
        </div>
      </div>
    </section>
    <question
      v-if="!is_first_view"
      series="item-suggestions"
    />
  </main>
</template>
<script>
import liff from '@line/liff'
import axios from 'axios'
import Question from '../../components/flow/Question.vue'

export default {
  name: 'FlowItemSuggestions',
  components: {
    Question
  },
  data(){
    return {
      is_first_view: true,
      hasSkintypeId: true
    }
  },
  mounted(){
    const id_token = liff.getIDToken()
    const target_url = process.env.VUE_APP_FLOW_URL + 'item-suggestions'
    axios
      .post(target_url, {id_token: id_token, command: 'has_skintype_id'})
      .then((res) => {
        // メジャー情報
        this.hasSkintypeId = res.data.has_skintype_id
      })
      .catch((err) => {
        console.log("FlowItemSuggestions", err.code, err.message)
      })
  },
  methods: {
    startFlow(){
      if(this.hasSkintypeId){
        this.is_first_view = false;
      }
    }
  }
}
</script>
<style scoped>
h1 {
    font-size: 20px;
    font-weight: bold;
    color: #F29F8F;
    padding: 12px 0 28px 0;
    text-align: center;
}
a {
  text-decoration: none;
  color: #707070;
}
.skincareItemCheck {
    background-color: #ffffff;
    font-size: 14px;
    padding: 0 40px;
}
.skincareItemCheck_container {
    padding: 45px 0;
}
.skincareItemCheck_container p:first-child{
  text-align: center;
}
.skincareItemCheck_explanation p:first-child {
    padding-bottom: 1em;
    text-align: left;
}
.skincareItemCheck_explanation {
    padding-bottom: 57px;
    text-align: left;
}
.itemSuggestions_btn {
    font-size: 24px;
    font-weight: bold;
    display: block;
    width: 90%;
    margin: 0 auto;
    border-radius: 100vh;
    text-align: center;
    padding: 15px 0;
    background-color: #F29F8F;
    color: #ffffff;
}
.itemSuggestions_btn_disabled {
    font-size: 24px;
    font-weight: bold;
    display: block;
    width: 90%;
    margin: 0 auto;
    border-radius: 100vh;
    text-align: center;
    padding: 15px 0;
    background-color: #707070;
    color: #ffffff;
}
.skintype_diag_notyet{
  line-height: 1.2;
}
.skintype_diag_notyet p{
  padding: 20px 10px;
  font-size: 18px;
}
.skintypeCheck_btn{
  padding-bottom: 42px;
}
.skintypeCheck_btn a{
  display: block;
  padding: 20px 30px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  background-color: #F29F8F;
  text-align: center;
  border-radius: 100vh;
  font-size: 20px;
}
.skintype_diag_notyet_container{
  padding: 0 40px;
}
</style>

